<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.friendEntId" filterable clearable url="api/distributor" placeholder="选择经销商" value-field="enterpriseId" @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <quick-select v-model="query.projectId" filterable clearable url="api/costBill/payProject" placeholder="收费类型" @change="toQuery" class="filter-item" style="width: 150px;" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button type="primary" class="filter-item" size="mini" icon="el-icon-plus" @click="add">新建</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="formCode" label="收费单号" width="130" fixed />
      <!-- <el-table-column prop="erpFormCode" label="ERP单号" width="130" fixed /> -->
      <el-table-column prop="friendEntName" label="经销商" min-width="200" show-overflow-tooltip />
      <el-table-column prop="projectName" label="收费类型" width="100" />
      <el-table-column prop="payMoney" label="收费金额" align="right" width="120" :formatter="$price" />
      <el-table-column label="付款期限" width="120" :formatter="r => {return new Date(r.payTime).format('yyyy/MM/dd');}" />
      <el-table-column label="创建时间" width="140" :formatter="r => {return new Date(r.createAt).format('yyyy/MM/dd hh:mm:ss');}" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="240" />
      <el-table-column label="状态" width="80" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <template v-if="scope.row.status === 'edit'">
            <el-button size="mini" type="text" @click="detail(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本收费单吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="doDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>
          <template v-else>
            <el-button size="mini" type="text" @click="detail(scope.row)">查看</el-button>

            <el-popover :ref="scope.row.id" placement="top" width="180" v-if="scope.row.status === 'unpay'">
              <p>确定取消本收费单吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="doCancel(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">取消</el-button>
            </el-popover>
          </template>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import { del, cancel } from "@/api/cost";
import initData from "@/mixins/initData";
import eForm from "./form";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: {
        edit: {
          type: "info",
          label: "编辑中",
        },
        unpay: {
          type: "warning",
          label: "侍支付",
        },
        pay: {
          type: "primary",
          label: "已支付",
        },
        paySuccess: {
          type: "success",
          label: "支付成功",
        },
        payFailed: {
          type: "error",
          label: "支付失败",
        },
        cancel: {
          type: "info",
          label: "已取消",
        },
      },
      query: {
        friendEntId: null,
        dateRange: null,
        projectId: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/costBill/seller";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      query.sort = "createAt,desc";
      query.isDeleted = false;
      this.params = query;
      return true;
    },
    add(inout, formType) {
      this.$refs.form && this.$refs.form.resetForm();
    },
    detail(row) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(row)));
    },
    doDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除收费单成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
    doCancel(id) {
      this.delLoading = true;
      cancel(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.init();
          this.$notify({
            title: "取消收费单成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/costBill/seller/download", this.params)
        .then((result) => {
          downloadFile(result, "收费单", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>